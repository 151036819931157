/* Estilo da barra de rolagem */
::-webkit-scrollbar {
  width: 12px;
}

/* Fundo da barra de rolagem */
::-webkit-scrollbar-track {
  background: linear-gradient(45deg, #0057a3, #72c4e7); /* Gradiente baseado na paleta */
  border-radius: 10px;
}

/* Cor da barra de rolagem */
::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, #72c4e7, #0057a3); /* Gradiente invertido */
  border-radius: 10px;
}

/* Cor ao passar o mouse sobre a barra de rolagem */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(45deg, #0057a3, #037fc9);
}
